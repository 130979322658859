@import "../../styles/core.scss";

.StoryModePage {
  color: $white-text;
  background-color: $black-background;
  font-family: "Pretendard";

  img {
    width: 100%;
  }

  .container {
    margin: 20px 0px;
  }
}

// Apply to all
.heading-container {
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 32px;
  }
}

// Containers and sections
.section-black {
  background-color: $black-000;
  border-radius: 20px;
  height: 600px;
  padding: 60px 42px;
  gap: 100px;
  border: 0.5px solid #3c3c3c;

  @media (max-width: 480px) {
    height: 100%;
    padding: 36px 16px;
    gap: 10px;
  }
}

.image-container-black {
  background-color: #000000;
  border-radius: 20px;
  padding: 32px;

  @media (max-width: 480px) {
    padding: 24px;
  }
}

// Divider
.divider {
  margin: 0 auto;
  border: 0;
  width: 100%;
  height: 1px;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.divider-orange {
  background: radial-gradient(
    ellipse at center,
    rgba(242, 132, 6, 0) 0%,
    #f28406 20%,
    rgba(242, 132, 6, 0) 100%
  );
}

.divider-white {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    /* Fully opaque white at the start */ #ffffff 1%,
    /* Solid white color at the center */ rgba(0, 0, 0, 0.5) 100%
      /* Semi-transparent black at the end */
  );
}

// HeadlineSection --- --- --- --- --- --- --- --- --- --- --- --- --- ---
.HeadlineSection {
  padding-top: 0px;
  .page-section-full-width {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 90vh;

    background-image: url(../../../public/images/storymode/headline_background_desktop.png);
    background-size: cover;
    background-repeat: no-repeat;

    /* Center the background image */
    background-position: center top -20px;

    /* Optional: Set a background color in case the image is missing or doesn't cover the entire viewport */
    background-color: #000000;

    @media (max-width: 480px) {
      background-image: url(../../../public/images/storymode/headline_background_mobile.png);
      justify-content: flex-end;
      background-position: center center -20px;
      padding: 0px 20px;
    }
  }

  .page-section-gutter {
    @media (max-width: 480px) {
      display: inline-block;
      align-self: flex-end;
      padding-bottom: 60px;
    }
  }
}

// BodySection --- --- --- --- --- --- --- --- --- --- --- --- --- ---
.BodySection {
  .ProblemSection {
    .divider {
      margin: 80px auto;
      width: 400px;

      @media (max-width: 480px) {
        margin: 40px auto;
        width: 240px;
      }
    }
    .page-section-full-width {
      margin-top: 60px;
      height: calc(100vw * 587 / 1440);
      background-image: url(../../../public/images/storymode/problem_background_desktop.png);
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: 480px) {
        height: calc(100vw * 587 / 845);
        background-image: url(../../../public/images/storymode/problem_background_mobile.png);
      }
    }

    .heading-container {
      margin: 0 auto;

      @media (max-width: 480px) {
        width: 75%;
      }

      .heading {
        @media (max-width: 480px) {
          font-size: 5vw;
        }
      }
    }
  }

  .SolutionSection {
    .heading-container {
      gap: 24px;
      margin: 0 auto;
    }

    padding: 120px 0px 60px;

    @media (max-width: 480px) {
      margin: 0 auto;
      padding: 60px 0px;
    }
  }

  .HomeSection {
    .section-black {
      background-image: url(../../../public/images/storymode/home_background_desktop.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .image-container {
      gap: 24px;

      @media (max-width: 480px) {
        gap: 12px;
        order: 2;
      }
      .item-1,
      .item-2 {
        width: 100%;
        max-height: 512px;
        justify-content: space-between;

        .item2-3 {
          img {
            width: 31.5%;
          }
        }
      }
    }

    .divider {
      width: 420px;
      margin: 40px 0px 80px;

      @media (max-width: 480px) {
        width: 80%;
        margin-bottom: 60px;
      }
    }
  }

  .MissionSection {
    .image-container {
      gap: 12px;

      @media (max-width: 480px) {
        max-width: 100%;
      }

      // .mission-1,
      // .mission-2,
      // .mission-3 {
      //   width: 80%;
      //   margin-right: 12px;

      //   @media (max-width: 480px) {
      //     //   width: 80%;
      //   }
      // }

      // .item1,
      // .item2,
      // .item3 {
      //   width: 100%;
      //   gap: 16px;
      // }

      .image-heading {
        text-align: right;

        @media (max-width: 480px) {
          text-align: left;
        }
      }
    }
    .button-reward {
      background-color: $orange-primary;
      border-radius: 12px;
      color: white;
      border: none;
      padding: 4px 10px;
    }
  }

  .CharacterSection {
    .image-container {
      @media (max-width: 480px) {
        order: 2;
      }
    }
  }

  .FunSection {
    .image-container {
      @media (max-width: 480px) {
        order: 2;
      }
    }
  }

  .EnglishSection {
    background: linear-gradient(to bottom, #131313, #000000);

    .page-section-gutter {
      max-width: 1300px;
    }

    .heading-container {
      gap: 24px;
      margin-bottom: 60px;
      @media (max-width: 480px) {
        text-align: center;
        margin-bottom: 20px;
      }

      .heading {
        @media (max-width: 480px) {
          font-size: 4vw;
        }
      }
    }

    .image-container {
      border-radius: 20px;
      gap: 24px;

      @media (max-width: 480px) {
        align-items: center;
        width: 90%;
        gap: 20px;
      }

      .ui-container {
        @media (max-width: 480px) {
          padding: 36px 0px 12px;
          gap: 12px;
        }

        .ui-image {
          padding: 24px 0px;
        }
      }
    }
  }
}

.EndSection {
  background-image: url(../../../public/images/storymode/beta_background.png);
  background-size: cover;
  background-position: top top;
  padding: 0px;

  .label {
    background-color: $orange-primary;
    border-radius: 12px;
    padding: 8px 40px;
    margin-top: 24px;
    font-weight: 800;

    @media (max-width: 480px) {
      padding: 6px 20px;
    }
  }

  .image-container {
    background: rgba(0, 0, 0, 0.4);
    padding: 60px 140px 36px;
    align-items: center;
    border: 0.5px solid rgb(166, 165, 165);

    border-radius: 20px;
    width: 80%;
    overflow: hidden;
    margin-top: 30px;
    gap: 20px;

    @media (max-width: 480px) {
      width: 100%;
      padding: 24px 20px;
    }

    .text-container {
      padding: 24px 0px;
      gap: 24px;

      @media (max-width: 480px) {
        gap: 12px;
      }
    }

    .beta-info {
      align-items: center;
      gap: 20px;

      .check {
        width: 32px;

        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }

    .button-cta {
      border-radius: 12px;
      width: 700px;
      padding: 12px;
      box-sizing: border-box;
      background-color: #2a2a2a;
      border: none;
      color: white;

      @media (max-width: 480px) {
        padding: 12px 40px;
        font-size: 24px;
      }
    }

    .button-cta:hover {
      background-color: white;
      color: black;
      cursor: pointer;
    }
  }
}
