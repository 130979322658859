$base-color: #c6538c;
$background-color: #131213;
$background-black: #000000;
$text-color-white: #ffffff;
$text-color-grey: #888888;
$point-color-orange: #f28406;
$d: 2500ms;
$angle: 90deg;
$gradX: 100%;
$radY: 50%;
$c1: rgba(168, 239, 255, 1);
$c2: rgba(168, 239, 255, 0.1);

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

.NewHome {
  font-family: "Poppins";
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: #f28406;
  --c2: rgba(168, 239, 255, 0.1);
}
@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

$desktop-width: 980px;

$border-dark: rgba($base-color, 0.88);

// Common

.img {
  width: 100%;
}

// Colors
.text-grey {
  color: #888888;
}

// Font Sizes

.section-title {
  // line-height: 1.07143;
  font-weight: 900;
  //   letter-spacing: -0.005em;
  // padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 23px;
    line-height: 1.2;
    // padding: 0 0 20px 0;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 769px) {
    font-size: 30px;
  }
}

.section-title-large {
  // line-height: 1.07143;
  font-weight: 900;
  //   letter-spacing: -0.005em;
  // padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 32px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 52px;
  }

  @media (min-width: 769px) {
    font-size: 60px;
  }
}

.section-title-small {
  // line-height: 1.07143;
  font-weight: 900;
  //   letter-spacing: -0.005em;
  padding: 0 0 30px 0;

  @media (max-width: 480px) {
    font-size: 28px;
    padding: 0 0 20px 0;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 769px) {
    font-size: 30px;
  }
}

.section-subtitle {
  line-height: 1.33341;
  font-weight: 500;
  //   letter-spacing: 0.009em;
  padding: 0 0 40px 0;

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 769px) {
    font-size: 18px;
  }
}

.section-subtitle-large {
  line-height: 1.33341;
  font-weight: 500;
  //   letter-spacing: 0.009em;
  padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 769px) {
    font-size: 20px;
  }
}

.section-subtitle-small {
  line-height: 1.33341;
  font-weight: 500;
  //   letter-spacing: 0.009em;
  padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 15px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 17px;
  }

  @media (min-width: 769px) {
    font-size: 21px;
  }
}

.section-tag {
  line-height: 1.33341;
  font-weight: 500;
  //   letter-spacing: 0.009em;
  padding: 0 0 12px 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 769px) {
    font-size: 20px;
  }
}

.section-body {
  line-height: 1.33341;
  font-weight: 400;
  //   letter-spacing: 0.009em;
  padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 12px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 769px) {
    font-size: 16px;
  }
}

.section-body-large {
  line-height: 1.33341;
  font-weight: 600;
  //   letter-spacing: 0.009em;
  padding: 0 0 20px 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 769px) {
    font-size: 20px;
  }
}

// Core
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

// Responsive

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(29, 29, 31);
  overflow-x: hidden;
}

.page-content {
  background-color: $background-color;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  color: $text-color-white;
}

.section-content {
  text-align: start;
  color: white;
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 86px;

  @media (max-width: 734px) {
    width: 87.5%;
    padding-top: 36px;
    padding-bottom: 44px;
  }

  @media (min-width: 735px) and (max-width: 1068px) {
    width: 692px;
    padding-top: 52px;
    padding-bottom: 74px;
  }

  @media (min-width: 1069px) {
    width: 980px;
    padding-top: 60px;
    padding-bottom: 84px;
  }
}

// HeadLineSection
.HeadlineSection {
  //   background-color: hsla(0, 0%, 8%, 1);
  //   .section-content {
  //     background-image: radial-gradient(
  //         at 69% 87%,
  //         hsla(30, 0%, 11%, 1) 0px,
  //         transparent 50%
  //       ),
  //       radial-gradient(at 54% 50%, hsla(35, 79%, 75%, 0.18) 0px, transparent 50%);
  //   }
  padding: 40px 0px 0px;
  text-align: center;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 60px 0px 20px;
  }

  .logo-image {
    margin-top: 40px;
    width: 140px;

    @media (max-width: 480px) {
      width: 120px;
      margin-top: 30px;
    }
  }

  .download-images {
    justify-content: center;
    gap: 20px;

    @media (max-width: 480px) {
      gap: 10px;
    }
  }
  .download-image {
    height: 60px;

    @media (max-width: 734px) {
      height: 42px;
    }
  }

  .button-download {
    position: fixed;
    max-width: 520px;
    width: 100%;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-family: "Poppins";

    padding: 10px 24px;
    border-radius: 50px;
    color: $point-color-orange;
    // border: 0.5px solid #f28406;
    // border-color: $point-color-orange;
    border: none;
    color: white;
    background-color: $point-color-orange;
    font-size: 22px;
    font-weight: 800;
    cursor: pointer;

    // background: linear-gradient(0deg, black, #444444);

    @media (max-width: 480px) {
      font-size: 14px;
      border-radius: 0px;
      bottom: 0px;
      height: 50px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // .glow-on-hover {
  //   padding: 10px 24px;
  //   font-size: 22px;
  //   border: none;
  //   outline: none;
  //   color: #fff;
  //   background: #111;
  //   cursor: pointer;
  //   position: fixed;
  //   bottom: 35px;
  //   z-index: 2;
  //   border-radius: 50px;

  //   @media (max-width: 480px) {
  //     font-size: 12px;
  //     padding: 10px 20px;
  //     width: 60%;
  //     bottom: 20px;
  //   }
  // }

  // .glow-on-hover:before {
  //   content: "";
  //   // background: linear-gradient(
  //   //   45deg,
  //   //   #ff0000,
  //   //   #ff7300,
  //   //   #fffb00,
  //   //   #48ff00,
  //   //   #00ffd5,
  //   //   #002bff,
  //   //   #7a00ff,
  //   //   #ff00c8,
  //   //   #ff0000
  //   // );
  //   background: linear-gradient(
  //     45deg,
  //     $point-color-orange,
  //     $text-color-white,
  //     #c16a01,
  //     #ffa733,
  //     white,
  //     $point-color-orange,
  //     #ffa733
  //   );
  //   position: absolute;
  //   top: -2px;
  //   left: -2px;
  //   background-size: 400%;
  //   z-index: -1;
  //   filter: blur(5px);
  //   width: calc(100% + 4px);
  //   height: calc(100% + 4px);
  //   animation: glowing 20s linear infinite;
  //   opacity: 1;
  //   transition: opacity 0.3s ease-in-out;
  //   border-radius: 12px;
  // }

  // .glow-on-hover:active {
  //   color: #000;
  // }

  // .glow-on-hover:active:after {
  //   background: transparent;
  // }

  // .glow-on-hover:hover:before {
  //   opacity: 1;
  // }

  // .glow-on-hover:after {
  //   z-index: -1;
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: #111;
  //   left: 0;
  //   top: 0;
  //   border-radius: 10px;
  // }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.ProductImageSection {
  padding: 0px 0px 40px 0px;
  text-align: center;
  margin: 0 auto;

  .section-content {
    @media (max-width: 480px) {
      width: 100%;
      padding: 0px;
    }
  }

  .section-background {
    width: 100%;
    border-radius: 50px;
    background-color: $background-black;
    padding: 0px 100px;

    @media (max-width: 480px) {
      background-color: none;
      padding: 0px;
    }
  }

  .product-image-big {
    max-width: 100%;
    height: 150%;
    position: relative;
    top: -40px;
    display: block;

    @media (max-width: 480px) {
      display: none;
    }

    @media (min-width: 480px) and (max-width: 980px) {
      width: 100%;
    }
  }

  .product-image-small {
    width: 100%;

    @media (max-width: 480px) {
      width: 100%;
    }

    @media (min-width: 480px) {
      display: none;
    }
  }
}

.ProblemSection2 {
  .divider-line {
    margin-left: 0;
    margin-bottom: 40px;
    border: 0;
    width: 415px;
    height: 1px;
    background: radial-gradient(
      rgba(255, 255, 255, 0) 0%,
      #fff 50.43%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (max-width: 480px) {
      width: 80%;
    }
  }

  .section-title {
    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  .section-subtitle {
    margin-top: 20px;
    color: $text-color-grey;
  }
}

.ProblemSection {
  text-align: center;

  .section-title {
    margin-bottom: 20px;
  }

  .image-small {
    width: 100%;

    @media (min-width: 480px) {
      display: none;
    }
  }
  .problem-containers {
    @media (max-width: 480px) {
      display: none;
    }
    gap: 50px;

    @media (max-width: 480px) {
      gap: 20px;
    }

    .problem-container {
      text-align: center;
      padding: 40px 0px 40px 0px;
      width: 33.3%;

      .section-body {
        @media (max-width: 480px) {
          font-size: 9.5px;
        }
      }

      .text-emphasis {
        font-size: 30px;
        font-weight: 600;

        @media (max-width: 480px) {
          font-size: 13px;
        }
      }

      .problem-stats {
        width: 90%;

        // @media (max-width: 480px) {
        //   height: 80px;
        // }

        // @media (min-width: 480px) and (max-width: 768px) {
        //   height: 220px;
        // }
      }
    }
  }
}

.TruthSection {
  padding: 140px 0px;
  background-color: rgb(28, 28, 28);
  color: white;
  text-align: start;

  .section-container {
    margin: 0 auto;
    justify-content: center;
    max-width: 980px;
  }
}

.SupportingHeadlineSection {
  //   background-color: rgb(28, 28, 28);
  text-align: center;

  .section-title {
    text-align: center;
  }
  .headline-image {
    align-items: center;
    width: 100vw;
    position: absolute;
  }

  .hero-image-big {
    margin-top: 40px;
    width: 100%;

    @media (max-width: 734px) {
      display: none;
    }
  }

  .hero-image-small {
    margin-top: 40px;
    width: 100%;

    @media (min-width: 481px) {
      display: none;
    }
  }
}

.HeroImageSection {
  background-color: $background-color;
  //   background-image: url(/public/images/newhome/hero_image.webp);
  max-width: 100%;
}

.PlanOverviewSection {
  text-align: start;
  background-color: $background-color;
  color: white;

  padding: 140px 0px 60px 0px;

  .section-subtitle {
    @media (max-width: 480px) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 480px) {
    padding: 60px 0px 20px 0px;
  }
}

.PlanOverviewImageSection {
  @media (max-width: 480px) {
    padding-bottom: 40px;
  }

  .flex-row {
    @media (max-width: 480px) {
      flex-direction: column;
      width: 87.5%;
      margin: 0 auto;
      text-align: start;
    }
  }

  .plan-container {
    flex-direction: row;
  }
  .image {
    width: 480px;

    @media (max-width: 480px) {
      width: 100%;
      border-radius: 12px;
    }

    @media (min-width: 480px) and (max-width: 768px) {
      font-size: 36px;
    }

    @media (min-width: 769px) {
      font-size: 62px;
    }
  }

  .image-text {
    text-align: start;
    color: white;
    align-self: center;
    padding: 40px 200px 40px 120px;

    @media (max-width: 480px) {
      padding: 0;
    }
  }

  .plan-container {
    align-items: center;
    text-align: start;

    .plan-image {
      width: 40px;
    }
  }
}

.IntroduceProductSection {
  .section-content {
    text-align: center;
    justify-content: center;

    .top-divider {
      align-items: center;
      gap: 14px;
      white-space: nowrap;
    }

    .divider-line-grey {
      background: grey;
      border: 0;
      color: white;
      height: 1px;
      width: 100%;
    }

    .section-subtitle-large {
      width: 60%;
      margin: 0 auto;
      margin-top: 20px;

      @media (max-width: 480px) {
        width: 100%;
      }
    }

    .mic-illustration {
      width: 450px;
      margin: 50px 0px;

      @media (max-width: 480px) {
        width: 100%;
        margin-top: 20px;
      }
    }

    .flag {
      width: 40px;
      opacity: 60%;
    }

    .supporting-languages {
      .flex-responsive-desktop {
        padding: 20px 0px;
        justify-content: space-around;
        display: flex;
        font-size: 10px;

        .language-container {
          gap: 10px;
        }

        @media (max-width: 480px) {
          display: none;
        }
      }

      .flex-responsive-mobile {
        padding: 20px 0px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 8px;
        gap: 20px;

        .language-container {
          gap: 10px;
        }

        @media (min-width: 481px) {
          display: none;
        }

        .flex-row {
          justify-content: space-around;
        }
      }

      .language_containers {
        padding: 20px 0px;
        justify-content: space-around;
        display: flex;
        // gap: 30px;
        font-size: 10px;

        .language-container {
          gap: 10px;
        }
      }

      .bottom-divider {
        background: $text-color-grey;
        border: 0;
        color: white;
        height: 1;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.InspirationSection {
  text-align: center;
}
.InspirationImageSection {
  .image-big {
    width: 100%;

    @media (max-width: 480px) {
      display: none;
    }
  }

  .image-small {
    width: 100%;

    @media (min-width: 480px) {
      display: none;
    }
  }
}

.ProductOverviewSection {
  margin-top: -140px;
  padding-top: 240px;
  gap: 40px;
  background-color: black;
  width: 100%;
  .section-content {
    text-align: center;
  }
  .image {
    width: 40px;
  }
}

.Benefit1Section,
.Benefit2Section,
.Benefit3Section {
  .section-content {
    text-align: center;

    .section-title {
      margin-bottom: 20px;
    }

    .section-subtitle {
      width: 70%;
      margin: 0 auto;

      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }

  gap: 12px;

  @media (max-width: 480px) {
    gap: 0px;
  }

  .section-background {
    margin-top: 105px;
    background-color: $background-black;
    border-radius: 20px;

    @media (max-width: 480px) {
      margin-top: 0px;
      padding: 30px 0px;
    }

    .flex-responsive {
      align-items: center;
      @media (max-width: 480px) {
        flex-direction: column;
        display: flex;
        gap: 30px;
      }

      @media (min-width: 481px) {
        flex-direction: row;
      }
    }

    img {
      width: 28%;
      position: relative;
      top: -65px;
      padding: 0px 18px;

      @media (max-width: 480px) {
        width: 80%;
        top: 0px;
        margin-bottom: 20px;
      }
    }
  }

  .image {
    width: 100%;
  }

  // .image-big {
  //   width: 100%;
  //   @media (max-width: 481px) {
  //     display: none;
  //   }
  // }

  // .image-small {
  //   width: 100%;
  //   @media (min-width: 480px) {
  //     display: none;
  //   }

  //   img {
  //     width: 100%;
  //   }
  // }
}

.TechFeaturesSection {
  .section-content {
    text-align: center;
  }

  .section-title {
    margin-bottom: 40px;

    @media (max-width: 480px) {
      padding: 60px 0px 0px;
    }
  }
  .technology-container {
    border-radius: 20px;
    padding: 20px;
    align-items: start;
    border: 0.01px solid #414141;
    background: linear-gradient(0deg, #111111, #1d1d1d);
    width: 300px;
    text-align: start;
    gap: 30px;

    @media (max-width: 480px) {
      padding: 60px 0px 0px;
    }

    .technology-title {
      background: linear-gradient(to right, #24af22, white);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 12px;
    }

    .technology-text {
      .span {
      }
      font-size: 20px;
      color: white;
    }
  }
}

.ResultSection {
  background-color: $background-black;
  background-image: linear-gradient($background-color, $background-black);
  text-align: center;

  padding: 60px 0px 20px;

  @media (max-width: 480px) {
    padding: 60px 0px 0px;
  }

  .section-title {
    margin: 0 auto;
    margin-bottom: 40px;

    @media (max-width: 480px) {
      text-align: left;
      width: 100%;
      margin-bottom: 20px;
    }

    @media (min-width: 481px) {
      width: 60%;
    }
  }

  .section-content {
    text-align: center;
  }
  .divider-line {
    margin: 0 auto;
    border: 0;
    width: 415px;
    height: 1px;
    background: radial-gradient(
      rgba(255, 255, 255, 0) 0%,
      #fff 50.43%,
      rgba(255, 255, 255, 0) 100%
    );
    margin-bottom: 40px;

    @media (max-width: 480px) {
      width: 60%;
      text-align: left;
    }
  }

  .result-containers {
    padding: 60px 0px;

    @media (max-width: 480px) {
      padding: 30px 0px;
    }

    .flex-responsive {
      justify-content: space-between;
      display: flex;

      @media (max-width: 480px) {
        flex-direction: column;
        gap: 40px;
      }

      @media (min-width: 481px) {
        flex-direction: row;
      }
    }

    .result-container {
      display: flex;
      justify-content: center;
      align-items: start;

      .number {
        align-items: center;
        gap: 4px;
        font-weight: 900;

        .counter {
          font-size: 42px;
          font-weight: 900;
        }

        .number_info {
          font-size: 42px;
        }

        .smaller {
          font-size: 10px;
          font-weight: 600;
          color: $text-color-grey;
          padding-top: 18px;
        }
      }

      .result-text {
        font-size: 12px;
        // font-style: italic;
        color: $text-color-grey;
      }
    }
  }

  .section-subtitle {
    font-size: 32px;
    font-weight: 500;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.ResultImageSection {
  margin-top: -20px;
  .result-image-big {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 480px) {
      display: none;
    }
  }

  .result-image-small {
    width: 100%;
    margin: 0 auto;

    @media (min-width: 481px) {
      display: none;
    }
  }
}

.TestimonialsSection {
  margin-top: 100px;

  .flex-row {
    gap: 30px;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .flex-column {
    gap: 40px;
  }

  .image {
    width: 200px;
  }
}

.ConversionSection {
  text-align: center;
  align-items: center;

  background-image: url("../../../public/images/newhome/conversion_image.webp");
  background-size: cover;
  background-position: center;

  padding: 140px 0px 80px 0px;

  @media (max-width: 480px) {
    padding: 60px 0px;
    background-image: url("../../../public/images/newhome/conversion_image_small.webp");
  }

  a {
    text-decoration: none;
  }

  .section-content {
    text-align: center;
    justify-content: center;
  }

  .section-title-large {
    margin: 0 auto;
    font-weight: 900;

    @media (max-width: 480px) {
      width: 100%;
      padding-top: 40px;
    }
  }
}

.button-30 {
  font-weight: 600;
  color: $point-color-orange;
  overflow: hidden;
  font-family: "Poppins";
  font-style: italic;
  margin-top: 20px;
  align-items: center;
  appearance: none;
  background-color: black;
  color: white;
  //   border-color: $point-color-orange;
  border: 0.35rem solid;
  border-radius: 0px;
  border-width: 0.1px;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: 30px 60px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 32px;
    // padding: 20px 8px;
    width: 80%;
  }

  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.25turn,
      var(--c1) 0.25turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.button-30:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -1px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.topbar {
  padding: 12px 0px;
  background-color: #121212;
  color: white;
  width: 100%;
  max-width: 100%;

  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: 600;

  .section-content {
    padding: 0px;
  }

  .top-bar-container {
    @media (max-width: 480px) {
      width: 100%;
    }

    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    max-width: 980px;

    .image-logo {
      align-self: center;
      @media (max-width: 480px) {
        height: 12px;
      }
      height: 16px;
    }

    .links {
      justify-content: end;
      font-weight: 400;
      font-size: 14px;

      @media (max-width: 480px) {
        font-size: 9px;
      }
    }
  }
}

.FooterSection {
  text-align: start;
  padding-bottom: 50px;
  background-color: rgb(17, 17, 17);
  font-size: 12px;

  @media (max-width: 481px) {
    font-size: 10px;
  }

  a {
    color: rgb(104, 102, 102);
  }

  .section-content {
    padding: 40px 0px;
    color: $text-color-grey;
  }

  // .download-images {
  //   gap: 10px;

  //   @media (max-width: 481px) {
  //     display: none;
  //   }
  // }

  .a-tag {
    margin-right: 10px;
  }

  .flex-row {
    justify-content: space-between;
    align-items: center;
  }

  .sns-link-container {
    max-width: 100px;
    gap: 12px;

    .sns-icon {
      width: 30px;
      @media (max-width: 481px) {
        width: 20px;
      }
    }
  }

  .company-info {
    gap: 12px;
    flex-direction: row;

    @media (max-width: 481px) {
      flex-direction: column;
      align-items: start;
      gap: 0px;
    }
  }

  .contact-containers {
    gap: 40px;
    justify-content: flex-start;
    display: flex;

    @media (max-width: 481px) {
      gap: 60px;
    }

    .contact-container {
      width: 30;

      .contact-title {
        font-size: 16px;
        color: white;

        @media (max-width: 481px) {
          font-size: 12px;
        }
      }

      .contact-link {
        @media (max-width: 481px) {
          font-size: 9px;
        }
      }
    }
  }
}

// ContactPage

.ContactPage {
  .section-content {
    text-align: center;
  }

  .section-title {
    padding: 0px 0px 12px 0px;
  }

  .section-subtitle {
    width: 70%;
    margin: 0 auto;
    padding: 0px 0px 40px 0px;
  }

  .form-container-background {
    background-color: $background-black;
    border-radius: 20px;
    padding: 46px;
    color: white;

    .form-left-container,
    .form-right-container {
      width: 50%;
    }

    .form-container {
      background-color: #101014;
      border: 0.05px solid #1d1e22;
      border-radius: 6px;
      padding: 8px;
      color: $text-color-white;
      text-decoration: none;
      font-size: 20px;
      margin-top: 6px;
    }

    .inquiry-container {
      height: 200px;
      width: 100%;
    }

    .form-email {
      margin-top: 40px;
    }

    .form-email,
    .form-name,
    .form-inquiry {
      text-align: left;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }

    .submit-container {
      margin-top: 50px;
      padding: 12px 60px;
    }

    input:required:invalid {
      border-color: rgb(175, 54, 54);
    }

    input:required:focus:valid {
      background: url("https://assets.digitalocean.com/labs/icons/hand-thumbs-up.svg")
        no-repeat 95% 50% lightgreen;
      background-size: 25px;
    }
  }

  .flex-responsive {
    @media (max-width: 480px) {
      flex-direction: column;
    }

    @media (min-width: 481px) {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  }

  .form-submit {
    margin: 0 auto;
  }

  .submit-container {
    border-radius: 50px;
    background-color: $background-color;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #f28406;
    padding: 12px 40px;
    margin-top: 20px;
  }
}

.FAQPage {
  // .faq-content {
  //   display: none;
  // }

  .faq-content.inactive {
    display: none;
  }
  .faq-content.active {
    color: black;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  }
}

.new-notification-bar {
  background-color: black;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px;
  border: 0.5px solid rgb(139, 139, 139);
  border-style: solid none;

  .responsive {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }

  .row-parent {
    display: flex;
    flex-direction: row;
  }

  .new-notification-bar-close {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0.6;

    justify-content: right;
    cursor: pointer;

    .close-button {
      width: 18px;

      @media (max-width: 375px) {
        width: 14px;
      }
    }
  }

  .new-notification-bar-container {
    line-height: 100%;
    display: flex;
    max-width: 1080px;
    align-items: center;
    justify-content: center;

    @media (min-width: 481px) {
      gap: 20px;
    }

    .new-discount-button {
      border-radius: 12px;
      background-color: #f23005;
      padding: 2px 10px;
      color: white;
      border-width: 0;
      font-weight: 700;
      font-size: 11px;
      margin-bottom: 6px;

      @media (min-width: 481px) {
        margin-bottom: 0px;
        font-size: 16px;
      }
    }

    .new-black-friday-text {
      color: white;
      font-weight: 800;
      font-size: 34px;
      line-height: 100%;
      margin-bottom: 10px;

      @media (min-width: 480px) {
        margin: 0px;
      }
    }

    .new-pricing-info {
      align-items: center;

      .new-desc {
        color: none;
        -webkit-text-stroke: 1px #f28406;
        margin-right: 12px;
        font-size: 22px;

        @media (min-width: 480px) {
          font-size: 34px;
        }
      }

      .new-price {
        color: #f28406;
        font-weight: 900;
        font-size: 30px;

        @media (min-width: 480px) {
          font-size: 34px;
        }
      }
    }
  }
}
