// Colors
$grey-text: #d9d9d9;
$white-text: #ffffff;
$black-background: #131213;
$black-000: #000000;
$orange-primary: #f28406;

// Font Sizes
// In order of size : headline - headline-small - heading - label-heading - image-heading - text-body - text-body-small

.title {
  font-size: 60px;
  font-weight: 900;
  line-height: 95%;
  @media (max-width: 480px) {
    font-size: 8.5vw;
    line-height: 70%;
  }
}

.headline {
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 8vw;
    margin-bottom: 12px;
  }
}

.headline-small {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 44px;

  @media (max-width: 480px) {
    width: 80%;
    font-size: 28px;
    margin-bottom: 26px;
  }
}

.subtitle-headline {
  font-size: 30px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.heading {
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 6.5vw;
  }
}

.label-heading {
  font-size: 20px;
  color: $orange-primary;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 4vw;
  }
}

.image-heading {
  font-size: 20px;
  color: $orange-primary;
  font-weight: 600;

  @media (max-width: 480px) {
    font-size: 18px;
  }
}

.text-body {
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 3.7vw;
  }
}

.text-body-small {
  font-size: 14px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
}

// Text Colors
.grey-text {
  color: $grey-text;
}

// Alignment
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.align-left {
  align-items: left;
}

.align-right {
  align-items: right;
}

.align-center {
  justify-content: center;
}

// Flex

.flex-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
}

// Page Sections
.page-section-full-width {
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 48px 16px;

  @media (max-width: 480px) {
    padding: 24px 16px;
  }
}

.page-section-gutter {
  max-width: 1100px;
  width: 100%;
  padding: 60px 42px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 48px 16px;
  }
}

// View Control

.desktop-only {
  @media (max-width: 480px) {
    display: none;
  }
}

.mobile-only {
  @media (min-width: 481px) {
    display: none;
  }
}

// Text Decorations

.no-decoration {
  text-decoration: none;
  color: white;
}

// Fade-in Effect
.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.4s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
