$base-color: #c6538c;
$background-color: #141414;
$background-black: #000000;
$text-color-white: #ffffff;
$text-color-grey: #888888;
$point-color-orange: #f28406;
$d: 2500ms;
$angle: 90deg;
$gradX: 100%;
$radY: 50%;
$c1: rgba(168, 239, 255, 1);
$c2: rgba(168, 239, 255, 0.1);

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(29, 29, 31);
}

.OldHome,
.AboutPage {
  font-family: "Inter", sans-serif;
}

div {
  box-sizing: border-box;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

// ------ ------ ------ ------ ------ ------ ------ ------ ------ ------

.top-bar {
  top: 0;
  width: 100%;
  max-width: 480px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 100;
  padding: 4px 12px;
  margin: 0 auto;
  align-items: center;
  position: sticky;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  .top-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: white;

    .pre-order-button {
      border-radius: 15px;
      background-color: black;
      color: white;
      font-weight: 600;
      font-size: 12px;
      border: none;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      line-height: 1.17648;
      letter-spacing: -0.022em;
    }

    .pre-order-text {
      color: #1076f9;
      font-weight: bold;
      font-size: 15px;
    }
    img {
      width: 100px;
      margin-top: 5px;
    }

    .links {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .top-bar-description {
    font-weight: bold;
  }

  .top-bar-logo {
    cursor: pointer;

    @media (max-width: 350px) {
      width: 120px;
    }
  }

  .top-bar-logo {
    width: 100px;
  }
}

.top-bar-v2 {
  display: flex;
  max-width: 480px;
  margin: 0 auto;
  justify-content: start;
  align-items: start;

  .top-bar-logo-v2 {
    width: 120px;
  }
}

.footer {
  padding: 32px 16px 100px;
  max-width: 480px;
  gap: 4px;
  font-size: 11px;
  background-color: #0001;
  margin: 0 auto;
  align-items: start;
  line-height: 150%;
  text-align: start;

  .company-sns-container {
    justify-content: space-between;

    .company-info {
      display: flex;
      gap: 12px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // margin-right: 30px;

      @media (max-width: 481px) {
        flex-direction: column;
        align-items: start;
        gap: 0px;
      }
    }

    .sns-link-container {
      gap: 10px;

      .sns-icon {
        width: 24px;
        @media (max-width: 481px) {
          width: 20px;
        }
      }
    }
  }
}

.footer a {
  color: #000;
}

.footer .refund-desc {
  font-size: 11px;
  color: grey;
  text-align: start;
}

.page-section {
  padding: 56px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
}

.transition-section {
  background-color: #fafbfc;
  font-size: 36px;
  font-weight: bold;
  margin: 0 auto;
  max-width: 480px;
  padding: 40px 0px;
  line-height: 150%;
}

// MAIN-SECTION

.main-section {
  gap: 20px;
  padding-top: 42px;

  .main-screen-img {
    z-index: -1;
    width: 360px;

    @media (max-width: 350px) {
      width: 300px;
    }
  }

  .main-demo {
    margin-top: -20px;
    font-size: 14px;
    font-weight: 800;
    color: white;
  }

  .button-demo {
    border-radius: 15px;
    background-color: #1076f9;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: 1.17648;
    font-weight: 400;
    letter-spacing: -0.022em;
  }

  .button-download {
    position: fixed;
    max-width: 360px;
    width: 100%;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-family: "Poppins";

    padding: 10px 24px;
    border-radius: 50px;
    color: $point-color-orange;
    // border: 0.5px solid #f28406;
    // border-color: $point-color-orange;
    border: none;
    color: white;
    background-color: $point-color-orange;
    font-size: 22px;
    font-weight: 800;
    cursor: pointer;

    // background: linear-gradient(0deg, black, #444444);

    @media (max-width: 480px) {
      max-width: 480px;
      font-size: 14px;
      border-radius: 0px;
      bottom: 0px;
      height: 50px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
//   .glow-on-hover {
//     padding: 10px 24px;
//     font-size: 22px;
//     border: none;
//     outline: none;
//     color: #fff;
//     background: #111;
//     cursor: pointer;
//     position: fixed;
//     bottom: 35px;
//     z-index: 2;
//     border-radius: 50px;

//     @media (max-width: 480px) {
//       font-size: 12px;
//       padding: 10px 20px;
//       width: 60%;
//       bottom: 20px;
//     }
//   }

//   .glow-on-hover:before {
//     content: "";
//     // background: linear-gradient(
//     //   45deg,
//     //   #ff0000,
//     //   #ff7300,
//     //   #fffb00,
//     //   #48ff00,
//     //   #00ffd5,
//     //   #002bff,
//     //   #7a00ff,
//     //   #ff00c8,
//     //   #ff0000
//     // );
//     background: linear-gradient(
//       45deg,
//       $point-color-orange,
//       $text-color-white,
//       #c16a01,
//       #ffa733,
//       white,
//       $point-color-orange,
//       #ffa733
//     );
//     position: absolute;
//     top: -2px;
//     left: -2px;
//     background-size: 400%;
//     z-index: -1;
//     filter: blur(5px);
//     width: calc(100% + 4px);
//     height: calc(100% + 4px);
//     animation: glowing 20s linear infinite;
//     opacity: 1;
//     transition: opacity 0.3s ease-in-out;
//     border-radius: 12px;
//   }

//   .glow-on-hover:active {
//     color: #000;
//   }

//   .glow-on-hover:active:after {
//     background: transparent;
//   }

//   .glow-on-hover:hover:before {
//     opacity: 1;
//   }

//   .glow-on-hover:after {
//     z-index: -1;
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: #111;
//     left: 0;
//     top: 0;
//     border-radius: 10px;
//   }

//   @media (max-width: 480px) {
//     font-size: 12px;
//   }
// }

/* Flex, row,  */
.row-parent {
  display: flex;
  flex-direction: row;
}

.grid-parent {
  display: grid;
}

.column-parent {
  display: flex;
  flex-direction: column;
}

// Divider

.grey-section-divider {
  height: 8px;
  border: none;
  background-color: #f5f5f7;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.grey-section-divider-thin {
  height: 2px;
  border: none;
  background-color: #f5f5f7;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.white-divider {
  height: 0.05px;
  opacity: 80%;
  background-color: white;
  width: 90%;
}

/* TEXT STYLES */

.style-bold-darkgrey {
  font-weight: 700;
  color: #86868b;
}

.style-bold-red {
  font-weight: 700;
  color: #ff0000;
}

.style-bold-blue {
  font-weight: 700;
  color: #1076f9;
}

.style-bold-green-chatgpt {
  font-weight: 700;
  color: #0aa37e;
}

.style-boldest {
  font-weight: 800;
}

.style-title-40 {
  font-size: 40px;
  font-weight: 700;
  line-height: 133%;

  @media (max-width: 375px) {
    font-size: 10vw;
  }
}

.style-normal-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 133%;

  @media (max-width: 375px) {
    font-size: 5vw;
  }
}

.style-title-32 {
  font-size: 32px;
  font-weight: 700;
  line-height: 133%;
  z-index: 1;

  @media (max-width: 375px) {
    font-size: 8vw;
  }
}

.style-subtitle-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 133%;

  @media (max-width: 375px) {
    font-size: 4vw;
  }
}

.style-desc-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;

  @media (max-width: 375px) {
    font-size: 3.5vw;
  }
}

.style-header {
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 375px) {
    font-size: 10vw;
  }
}

.wake-section {
  position: relative;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: start;
  padding: 52px 22px;
}

.wake-section2 {
  position: relative;
  background-color: #000;
  color: #fff;
  display: flex;
  padding: 52px 22px;
}

.wake-section.gap,
.wake-section2.gap {
  gap: 48px;
}

.wake-section-comp-row {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.wake-section .wake-section-comp-col3-1 {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: 30px;
  width: 100%;
}

.wake-section .wake-section-comp-col3-2 {
  display: flex;
  flex-direction: column;
  text-align: end;
  align-items: end;
  width: 100%;
}

.wake-section .wake-label {
  font-size: 20px;
  text-decoration: underline;
  font-weight: bolder;
}

.wake-section .wake-section-comp-col3-1,
.wake-section .wake-section-comp-col3-1 .wake-title,
.wake-section .wake-section-comp-col3-2 .wake-title {
  font-size: 32px;
  margin-bottom: 30px;

  @media (max-width: 350px) {
    font-size: 28px;
  }
}

.wake-section-comp-col .wake-section-sub {
  position: relative;
  top: 0;
  left: 0;
  height: 280px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wake-section-sub .backdrop {
  position: absolute;
  top: 0;
  left: 60px;
}

.section-quote {
  font-size: 18px;
}

.section-author-background {
  margin-top: 4px;
}

/* .wake-section-sub .section-quote {
  font-size: 16px;
} */

.text-start {
  text-align: start;
}

.sub-label {
  font-size: 16px;
  color: #7d7878;
  font-weight: bold;
  margin-bottom: 4px;
}

.technology-section {
  background-color: white;
  gap: 24px;
}

.product-section {
  gap: 20px;

  .section-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 150%;
  }

  .section-subtitle {
    font-size: 16px;
  }

  .ui-container {
    background-color: #fafbfc;
    max-width: 480px;
    width: 100%;

    padding: 32px 0px 30px;
    border-radius: 40px;

    @media (max-width: 350px) {
      padding: 30px 0px 8px;
    }

    .row-parent {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      padding: 30px 0px;

      .ui-flashcard {
        position: absolute;
        right: 45%;
        width: 160px;
        bottom: 40px;

        @media (max-width: 350px) {
          width: 140px;
        }
      }

      .ui-conversation {
        display: block;
        position: relative;
        width: 180px;
        left: 50px;

        @media (max-width: 350px) {
          width: 160px;
        }
      }
    }

    .grid-parent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 10px;
      column-gap: 0px;

      justify-content: center;

      font-size: 17px;
      font-weight: 700;

      margin: 20px;

      @media (max-width: 350px) {
        font-size: 13px;
        padding: 0px 10px;
      }

      .style-bold-darkgrey {
        color: #86868b;
      }
    }
  }
}

.side-padding {
  padding: 0px 16px;
}

.image-col {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
}

.image-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  overflow-x: hidden;

  @media (max-width: 350px) {
    gap: 10px;
  }
}

.product-section .product-ui {
  @media (max-width: 350px) {
    width: 230px;
  }
}

.demo-video {
  width: 100%;
  aspect-ratio: 9 / 16;
}

.demo-pre-order {
  color: #1076f9;
  cursor: pointer;
  text-decoration: none;
}

.tech-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.tech-square {
  width: 65px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}

.tech-square .tech-label {
  font-size: 12px;
  padding: 4px;
  font-weight: 600;
}
.feature-row {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
}

.feature-image-box {
  border-radius: 4px;
  width: 60px;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Promise-section

.promise-section {
  padding: 80px 12px 80px;
  background-color: #fafbfc;
  gap: 20px;

  .subtitle {
    color: #555555;

    @media (max-width: 375px) {
    }
  }

  .promise-comp-row {
    gap: 80px;

    @media (max-width: 375px) {
      gap: 20vw;
    }

    .vs {
      margin-top: 100px;
    }

    img {
      width: 160px;
      @media (max-width: 375px) {
        width: 35vw;
      }
    }
  }

  .text-container {
    border-radius: 40px;
    max-width: 480px;
    width: 100%;
    text-align: left;
    font-weight: 600;

    .text-content-1,
    .text-content-2 {
      font-size: 26px;
      padding: 20px;
      gap: 10px;
      margin: 40px 0px;
      line-height: 150%;
      font-weight: 500;

      @media (max-width: 375px) {
        font-size: 20px;
      }

      .logo {
        font-size: 44px;
        font-weight: 900;
        margin-top: 16px;

        @media (max-width: 375px) {
          font-size: 40px;
        }
      }

      .blue-section-divider {
        width: 40%;
        height: 8px;
        background-color: #1076f9;
        margin: 60px 0px 30px;
        border: none;
      }

      .style-bold-blue {
        font-weight: 700;
        color: #1076f9;

        @media (max-width: 375px) {
          font-size: 20px;
        }
      }
    }
  }
}

.promise-comp-row {
  display: flex;
  margin: 50px 0px 40px;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width: 350px) {
    gap: 20px;
  }
}

.technology-row {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
}

/* UpgradeSection */

.upgrade-section {
  .upgrade-content {
    background-color: white;
    text-align: left;
    padding: 0px 12px;

    .title {
      font-size: 32px;
      margin-bottom: 40px;
      font-weight: 700;
      line-height: 150%;

      @media (max-width: 375px) {
        font-size: 9vw;
      }
    }

    .upgrade-name {
      font-size: 16px;
      font-weight: bold;
      align-items: center;
    }

    .upgrade-adjective {
      font-size: 30px;
    }

    .upgrade-description {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .column-parent {
    flex-direction: column;
    line-height: 220%;

    .grid-parent {
      display: grid;
      grid-template-columns: 1fr 2fr 11fr;
    }
    .column-comp {
      margin: 40px 0px;
    }

    .comp-icon {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }
}
.technology-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 150px;
}

.promise-graph-part {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  margin: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}

.promise-graph-part .title {
  font-size: 18px;
  font-weight: 600;
  text-align: start;
}

.promise-graph-part .subtitle {
  font-size: 16px;
  color: #7d7878;
  text-align: start;
}

.promise-graph-part .center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.old-promise {
  text-decoration: line-through;
  margin: 8px 0;
}

.promise-title {
  font-size: 28px;
  font-weight: bold;
  margin: auto;
  text-align: center;

  @media (max-width: 350px) {
    font-size: 26px;
  }
}

.promise-section .versus {
  margin-top: 30px;
}

.new-promise {
  font-size: 48px;
  font-weight: bold;
}

.new-promise.red {
  background: linear-gradient(
    90deg,
    rgb(255, 89, 89) 0%,
    rgba(255, 89, 89, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.new-promise.green {
  background: linear-gradient(
    90deg,
    rgb(0, 187, 41) 0%,
    rgba(0, 187, 41, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.new-promise.blue {
  background: linear-gradient(
    90deg,
    rgb(16, 118, 249) 0%,
    rgba(16, 118, 249, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divider {
  background-color: #d2d2d7;
  height: 1px;
  width: 100%;
  margin: 8px 0;
}

.promise-graph-part .bar-row {
  display: flex;
  width: 100%;
  align-items: center;
}

.promise-graph-part .bar-row .full-bar {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 84, 84, 0.2) 100%
  );
  height: 12px;
  flex-grow: 1;
  border-radius: 6px;
}

.promise-graph-part .bar-row .duo-bar {
  background: linear-gradient(
    90deg,
    rgba(0, 187, 41, 1) 0%,
    rgba(84, 255, 122, 0.2) 100%
  );
  height: 12px;
  width: 40%;
  border-radius: 6px;
}

.promise-graph-part .bar-row .babbel-bar {
  background: linear-gradient(
    90deg,
    rgba(253, 178, 109, 1) 0%,
    rgba(255, 165, 0, 0.2) 100%
  );
  height: 12px;
  width: 40%;
  border-radius: 6px;
}

.promise-graph-part .bar-row .bar-label {
  font-size: 16px;
  color: #7d7878;
  margin-left: 8px;
}

.system-section {
  background-color: #fff;

  .system-section.page-section {
    @media (max-width: 350px) {
      padding-top: 0px;
    }
  }

  .language-title {
    font-size: 14px;
    margin: 40px 0px 10px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .flag-container {
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: 350px) {
      gap: 10px;
    }

    .flag-row {
      gap: 12px;
    }
  }

  .lang-button {
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .lang-button.pointer {
  //   cursor: pointer;
  // }

  // .lang-button.selected {
  //   border: 3px solid #1076f9;
  // }

  .feature-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 72px;
    position: relative;
    top: 0;
    left: 0;

    .feature-image {
      width: 100%;
      text-align: center;
      margin-top: 12px;
    }

    .numbering {
      position: absolute;
      top: 48px;
      right: 0;
      font-size: 80px;
      font-weight: bold;
      color: #ececec;
      z-index: -0;
    }
  }
}

.SystemTransition {
  @media (max-width: 350px) {
    font-size: 32px;
  }
}

.action-section {
  background-color: #000;
  color: #fff;
  gap: 20px;

  .section-title {
    font-size: 36px;
    margin-bottom: 5px;

    @media (max-width: 375px) {
      font-size: 8vw;
    }
  }

  .price-box img {
    width: 90%;
  }

  .price-reference img {
    width: 86%;
  }

  .white-divider {
    height: 0.05px;
    width: 85%;
    opacity: 60%;
  }

  .benefit-header {
    font-size: 26px;

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  .benefit-container {
    display: flex;
    flex-direction: row;
    padding: 10px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: #242222;
    color: white;
    max-width: 100%;
    width: 85%;

    .benefit-description {
      display: flex;
      flex-direction: column;
      align-items: start;

      .title {
        font-size: 21px;
        font-weight: 600;

        @media (max-width: 350px) {
          font-size: 19px;
        }
      }

      .description {
        font-size: 16px;
        font-weight: normal;

        @media (max-width: 350px) {
          font-size: 13px;
        }
      }
    }
    .benefit-image img {
      width: 40px;
      height: 40px;

      @media (max-width: 375px) {
        width: 35px;
        height: 35px;
      }
    }
  }

  .pre-order-discord {
    font-size: 14px;
    color: #ccc;
    margin-top: -30px;

    a {
      color: #ffaa20;
    }
  }

  .pre-order-button:hover {
    background-color: #fffb;
  }

  .pre-order-button {
    // cursor: pointer;
    // background: linear-gradient(90deg, #ffaa20 0%, #ffdb2d 100%);
    background: rgb(169, 169, 169);
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    box-sizing: border-box;
    height: 62px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    border: 2px solid white;
  }

  .pre-order-refund {
    font-size: 16px;
    color: grey;
    margin-top: -30px;
  }

  .pre-order-date {
    color: #ccc;
    opacity: 0.3;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0em;

    @media (max-width: 350px) {
      font-size: 20px;
    }
  }
}

.price-label {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 8px;
}

.pre-order-bonus-text {
  margin-top: 36px;
  background: linear-gradient(90deg, #ffaa20 0%, #ffdb2d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 24px;
}

.pre-order-bonus-row {
  display: flex;
  gap: 12px;
  margin-top: 24px;
  background-color: white;
  color: black;
  border-radius: 16px;
  padding: 32px;
  width: 280px;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
}

.pre-order-bonus-label {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.benefit-header {
  font-weight: bold;
  font-size: 20px;
}

.pre-order-bonus-box {
  background-color: white;
  border-radius: 8px;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  font-size: 12px;
}

.original-price {
  margin-top: 20px;
  margin-bottom: 12px;
  text-decoration: line-through;
  color: #7d7878;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0em;
}

.action-section .section-hook {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 3px;
}

.current-price {
  color: #fff;
  font-size: 44px;
  font-weight: 800;
  letter-spacing: 0em;
}

.current-price span {
  font-size: 12px;
  font-weight: bold;
}

.price-explain {
  font-size: 12px;
  color: #7d7878;
}

.pre-order-bonus {
  color: #ccc;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-bottom: 24px;
}

.pre-order-scarcity {
  color: #ccc;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  font-style: italic;
  margin-top: 12px;
}

.subscribe-section {
  .section-title {
    font-size: 18px;
    font-weight: lighter;
    margin-top: 30px;

    @media (max-width: 375px) {
      font-size: 16px;
    }
  }

  .subscribe-hook {
    font-size: 32px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;

    @media (max-width: 375px) {
      font-size: 28px;
    }
  }

  .subscribe-form {
    display: flex;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .subscribe-input {
    font-size: 16px;
    width: 200px;
    padding: 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #d2d2d7;
    border-right: none;

    @media (max-width: 350px) {
      width: 160px;
    }
  }

  .subscribe-input:focus {
    outline: none;
  }

  .subscribe-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background-color: #1076f9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

// PROBLEM SECTION ----------------------------

.problem-section {
  background-color: #fafafa;

  .problem-title-line-1 {
    font-size: 28px;
    color: red;
    font-weight: bold;
  }

  .problem-sentence {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 60px;
  }

  .problem-icon {
    margin-top: 30px;
    margin-bottom: 24px;
  }

  .problem-red {
    color: red;
    font-weight: bold;
  }

  .problem-title-line-2 {
    font-size: 42px;
    color: red;
    font-weight: bold;
  }

  .problem-solution {
    font-weight: bold;
    font-size: 28px;
  }
}

.white-divider {
  width: 100%;
  height: 12px;
  color: white;
}

.main-title {
  font-size: 38px;
  margin-bottom: 24px;
}

.wake-author {
  font-style: italic;
  margin-top: 12px;
}

.wake-title {
  margin-top: 12px;
  margin-bottom: 80px;
  font-size: 32px;
  font-weight: bold;
}

.wake-subtitle {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.compare-section {
  background-color: white;
  margin-bottom: 20px;

  .section-subtitle {
    margin-bottom: -20px;
  }
  .criterion .icon img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .comparison-table {
    display: flex;
    gap: 12px;
    margin-top: 42px;

    @media (max-width: 350px) {
      font-size: 14px;
      gap: 2px;
    }

    .criteria-column {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 160px;
      padding-top: 40px;

      @media (max-width: 350px) {
        width: 130px;
      }
    }

    .criterion {
      display: flex;
      gap: 12px;
      font-weight: bold;
      align-items: center;
      height: 60px;
    }

    .criterion-label {
      font-size: 14px;
      font-weight: normal;
      text-align: start;

      @media (max-width: 350px) {
        font-size: 12px;
      }
    }

    .criteria-column img {
      width: 30px;
      height: 30px;

      @media (max-width: 350px) {
        width: 25px;
        height: 25px;
      }
    }

    .product-column {
      width: 80px;
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 8px 4px;
      gap: 12px;
    }

    .chatgpt-label {
      font-weight: bold;
      color: #787878;
    }

    .langgpt-label {
      font-weight: bold;
    }

    .icon-div {
      height: 60px;
      display: flex;
    }

    .icon-div img {
      width: 20px;
      height: 20px;
      margin: auto;

      @media (max-width: 350px) {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.Feature-section {
  background-color: white;
  color: white;
  text-align: left;
  max-width: 480px;
  padding: 0;
  margin-bottom: 300px;

  @media (max-width: 350px) {
    margin-bottom: 220px;
  }

  .Feature-background {
    width: auto;
    background-color: black;
    height: 700px;
    padding: 52px 0px;
  }

  .images img {
    overflow: visible;
    width: 280px;

    @media (max-width: 350px) {
      width: 220px;
    }
  }
}

.Feature-section .Feature-title {
  text-align: left;
  font-size: 33px;
  font-weight: bolder;
  padding: 0px 32px;
}

.Feature-section .Feature-subtitle {
  padding: 0px 32px;
  margin-top: 32px;
  font-size: 21px;
  font-weight: 600;
}

.Feature-section-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.Feature-section-comp-row {
  margin: 30px 0px 20px;

  font-size: 16px;
  display: flex;
  gap: 25px;
}

.Feature-section .grammar-button,
.Feature-section .translation-button {
  cursor: pointer;
  color: grey;
}

.Feature-section .underlined {
  color: white;
  text-decoration: underline;
}

.cupertino-pane .content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 36px 20px;
  gap: 20px;
  --cupertino-pane-move-background: black;

  .description-text {
    padding: none;
    margin: none;
    margin: 10px;
    color: #757575;
  }
  .title {
    color: black;
    font-size: 26px;
    font-weight: bolder;
    padding: 0px 0px;
    line-height: 150%;

    @media (max-width: 375px) {
      font-size: 24px;
    }

    @media (max-width: 340px) {
      font-size: 22px;
    }
  }

  .subtitle {
    color: #757575;
    font-size: 13px;
    padding: 20px 0px;

    @media (max-width: 375px) {
      font-size: 13px;
    }

    @media (max-width: 340px) {
      font-size: 12px;
    }
  }

  .button-stripe {
    max-width: 100%;
    width: 100%;
    padding: 0px 20px;
  }

  .button-box {
    background-color: black;
    border: none;
    color: white;
    font-weight: bolder;
    border-radius: 10px;
    width: 100%;
    height: 56px;
    margin-top: 5px;
    font-size: 16px;
  }
  .emphasis-purple {
    color: #6772e5;
  }

  .emphasis-bold {
    font-weight: bolder;
  }

  .button-stripe {
    position: fixed;
    bottom: 10px;
  }

  .button-box:hover {
    cursor: pointer;
  }

  .explanation-text {
    font-size: 10px;
    color: #757575;
    margin-top: 5px;
  }
}

// Transitions

.promise-transition {
  padding: none;

  .row-parent {
    height: 100%;
  }
}

.upgrade-transition {
  background-color: white;
  padding: 80px 16px;
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  line-height: 200%;

  font-size: 18px;
  font-weight: 600;

  @media (max-width: 350px) {
    font-size: 16px;
  }

  .style-bold-green-chatgpt {
    font-weight: 700;
    color: #0aa37e;
  }
}

.language-section {
  background-color: white;
  align-items: start;
  text-align: start;

  .header-content {
    text-align: left;
    gap: 10px;

    .subtitle {
      font-size: 18px;
      font-weight: bold;
    }

    .title {
      font-size: 42px;
      font-weight: 700;

      @media (max-width: 350px) {
        font-size: 36px;
      }
    }

    .description {
      margin-top: 10px;
      font-size: 18px;

      @media (max-width: 350px) {
        font-size: 14px;
      }
    }
  }

  .grid-parent {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 55px;
    margin: 80px auto 40px;

    @media (max-width: 350px) {
      gap: 40px;
    }

    .grid-comp {
      align-items: center;
      text-align: center;
      font-size: 16px;
      gap: 10px;

      @media (max-width: 350px) {
        font-size: 12px;
      }
    }

    .language-img img {
      width: 46px;

      @media (max-width: 350px) {
        width: 40px;
      }
    }

    .language-text {
      margin-top: 10px;
    }
  }

  .discord-content {
    margin: 40px auto 0px;
    text-align: center;

    .discord-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #7289da;

      a {
        text-decoration: none;
        text-decoration: underline;
      }
    }

    .discord-description {
      font-size: 14px;
    }
  }
}

.system-transition {
  margin: 20px auto;
}

.BottomBar {
  background-color: black;
  color: white;
  max-width: 480px;
  width: 100%;
  position: sticky;
  bottom: 0;
}

// Version 2

.action-section-v2 {
  background-color: #000;
  color: #fff;

  .price-label {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .pre-order-bonus-text {
    margin-top: 36px;
    background: linear-gradient(90deg, #ffaa20 0%, #ffdb2d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 24px;
  }

  .pre-order-bonus-row {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    background-color: white;
    color: black;
    border-radius: 16px;
    padding: 32px;
    width: 280px;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
  }

  .pre-order-bonus-label {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .benefit-header {
    font-weight: bold;
    font-size: 20px;
  }

  .pre-order-bonus-box {
    background-color: white;
    border-radius: 8px;
    height: 120px;
    width: 120px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    font-size: 12px;
  }

  .pre-order-button {
    // cursor: pointer;
    // background: linear-gradient(90deg, #ffaa20 0%, #ffdb2d 100%);
    background: rgb(152, 152, 152);
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    box-sizing: border-box;
    height: 50px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    margin-bottom: 12px;
    margin-top: 36px;
  }

  .pre-order-discord {
    color: #ccc;
    margin-top: 4px;
  }

  .pre-order-discord a {
    color: #ffaa20;
  }

  .pre-order-button:hover {
    background-color: #fffb;
  }

  .original-price {
    margin-top: 20px;
    margin-bottom: 12px;
    text-decoration: line-through;
    color: #7d7878;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0em;
  }

  .action-section .section-hook {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 3px;
  }

  .current-price {
    color: #fff;
    font-size: 44px;
    font-weight: 800;
    letter-spacing: 0em;
  }

  .current-price span {
    font-size: 12px;
    font-weight: bold;
  }

  .price-explain {
    font-size: 12px;
    color: #7d7878;
  }

  .pre-order-bonus {
    color: #ccc;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    margin-bottom: 24px;
  }

  .pre-order-scarcity {
    color: #ccc;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    font-style: italic;
    margin-top: 12px;
  }

  .pre-order-date {
    color: #ccc;
    opacity: 0.3;
    font-size: 44px;
    font-weight: 800;
    letter-spacing: 0em;
    margin-top: 42px;
  }
}

.AboutPage {
  .title {
    font-size: 30px;
    font-weight: 700;
    @media (max-width: 375px) {
      font-size: 7vw;
    }
  }
  .divider {
    border: 0px;
    height: 1px;
  }
  .section-container {
    background-color: white;
    margin: 30px 0px;

    .body-text {
      display: flex;
      flex-direction: column;
      gap: 25px;
      font-size: 20px;
      line-height: 1.5;
      text-align: left;

      @media (max-width: 375px) {
        font-size: 4vw;
      }
    }

    .img-container {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.waitlist-section {
  background-color: black;
  color: white;
  text-align: left;
  gap: 20px;

  .section-title {
    font-size: 46px;
    font-weight: 600;
    text-align: left;
    width: 100%;

    @media (max-width: 375px) {
      font-size: 10vw;
    }
  }

  .section-subtitle {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 375px) {
      font-size: 5vw;
    }
  }

  .learner-diagnosis {
    margin: 40px;
    display: flex;
    flex-direction: column;

    gap: 20px;
    text-align: center;
    width: 100%;
    align-items: center;

    @media (max-width: 375px) {
      margin: 0px 20px;
    }

    .diagnosis-header {
      font-size: 22px;
      font-weight: 600;

      @media (max-width: 375px) {
        font-size: 4.2vw;
      }
    }

    .diagnosis-description-container {
      padding: 12px;
      background-color: #1f1f1f;
      border-radius: 20px;
      gap: 20px;
      width: 90%;

      .diagnosis-description {
        display: flex;
        justify-content: left;
        align-items: center;

        width: 100%;

        .tick-image {
          width: 30px;
          height: 30px;

          @media (max-width: 375px) {
            width: 7vw;
            height: 7vw;
          }
        }

        .description-text {
          text-align: left;
          font-size: 22px;
          line-height: 1.5;
          padding: 20px;
          font-weight: 600;

          @media (max-width: 375px) {
            font-size: 4vw;
          }
        }
      }
    }
  }

  .waitlist-button {
    border-radius: 12px;
    background-color: #ffaa20;
    // background-color: linear-gradient(90deg, #ffaa20 0%, #ffdb2d 100%);
    color: white;
    font-weight: 600;
    font-size: 24px;
    height: 52px;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 75%;
    margin-top: 0px;

    @media (max-width: 375px) {
      font-size: 6vw;
    }
  }
}

// Notification bar for collecting survey
// .notification-bar {
//   background-color: black;
//   color: white;
//   height: 150px;
//   padding: 20px;
//   position: relative;

//   @media (max-width: 375px) {
//     height: 18vh;
//   }

//   .notification-bar-close {
//     position: absolute;
//     right: 12px;
//     top: 12px;
//     opacity: 0.6;
//     justify-content: right;
//     cursor: pointer;

//     .close-button {
//       width: 18px;

//       @media (max-width: 375px) {
//         width: 14px;
//       }
//     }
//   }

//   .notification-bar-container {
//     width: 100%;
//     margin: 20px auto 0px auto;

//     @media (max-width: 375px) {
//       margin: 0px auto 0px auto;
//     }

//     .text-container {
//       text-align: start;
//       justify-content: start;
//       justify-content: center;

//       width: 70%;

//       .text {
//         font-size: 30px;
//         font-weight: 700;

//         .text-small {
//           font-size: 14px;
//           font-weight: 600;

//           @media (max-width: 375px) {
//             font-size: 3vw;
//           }
//         }

//         .text-big {
//           cursor: pointer;
//           text-decoration: underline;

//           @media (max-width: 375px) {
//             font-size: 6vw;
//           }
//         }
//       }
//     }
//     .logo-image {
//       width: 120px;
//       opacity: 0.4;

//       @media (max-width: 375px) {
//         width: 30vw;
//       }
//     }

//     .notification-bar-action {
//       text-decoration: underline;
//       font-size: 24px;
//       cursor: pointer;
//     }
//   }
// }

.notification-bar {
  background-color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  padding: 20px;

  .interactive {
    flex-direction: row;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .row-parent {
    display: flex;
    flex-direction: row;
  }

  .notification-bar-close {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0.6;
    justify-content: right;
    cursor: pointer;

    .close-button {
      width: 18px;

      @media (max-width: 375px) {
        width: 14px;
      }
    }
  }

  .notification-bar-container {
    line-height: 100%;
    .discount-button {
      border-radius: 12px;
      background-color: #f23005;
      padding: 2px 10px;
      color: white;
      border-width: 0;
      font-weight: 700;
      font-size: 11px;
      margin-bottom: 6px;
    }

    .black-friday-text {
      color: white;
      font-weight: 800;
      font-size: 34px;
      line-height: 100%;
      margin-bottom: 10px;
    }

    .pricing-info {
      align-items: center;
      .desc {
        color: none;
        -webkit-text-stroke: 1px #f28406;
        margin-right: 12px;
        font-size: 22px;

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }

      .price {
        color: #f28406;
        font-weight: 900;
        font-size: 30px;

        @media (max-width: 480px) {
        }
      }
    }
  }
}

.TESTIMONIAL-SECTION {
  background-color: #f5f5f7;
  padding: 52px 40px;

  .testimonial-container {
    justify-content: center;
    align-items: center;
    display: flex;

    .testimonial-rating {
      margin-bottom: 25px;

      .star {
        width: 30px;
      }
    }
    .testimonial-headline {
      font-size: 46px;
      font-weight: 800;
      color: $point-color-orange;
      margin-bottom: 10px;

      @media (max-width: 480px) {
        font-size: 38px;
      }
    }

    .testimonial-content {
      font-size: 37px;
      margin-bottom: 50px;
      font-weight: 500;

      @media (max-width: 480px) {
        font-size: 30px;
      }
    }

    .testimonial-name {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 10px;
      color: $point-color-orange;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    .testimonial-language {
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
}
